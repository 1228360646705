import React from 'react';
import { Link } from 'react-router-dom';

export default class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link to={'/'} className={'navbar-brand'}>
                        SportsMarkit
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item float-right">
                                <Link className={'nav-link'} to={'/agora'}>
                                    Camera Live
                                </Link>
                            </li>

                            <li className="nav-item float-right">
                                <Link className={'nav-link'} to={'/rtmp'}>
                                    RTMP Live
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}