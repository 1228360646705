
const config = {
    appId: 'c3020a86ba414c68a3607bf74c7f7b06',
    token: '006c3020a86ba414c68a3607bf74c7f7b06IAA03d0UJhbK88zXE9Dh1s167QmkZrr/uYv8ppP4XxUuj6kPKjgAAAAAEACLUtgwi9/ZXwEAAQCL39lf',
    channel: 'helloWorld',
    certificate: 'c968cd5d9d17435b880f91cb0db66538',
    apiPath: 'https://api.sportsmarkit.com/rdash',
    rtmpPath: 'https://api.sportsmarkit.com'
    // rtmpPath: 'http://api.sportsmarkit.com:8888'
}

export default config;