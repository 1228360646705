import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './LiveStreams.css';
// import config from '../../server/config/default';
import config from '../config';

export default class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            live_streams: []
        }
    }

    componentDidMount() {
        this.getLiveStreams();
    }

    async getLiveStreams() {
        try {
            const res = await axios.get(`${config.rtmpPath}/api/streams`)
            let streams = res.data;
            console.log(res)
            if (typeof (streams['live'] !== 'undefined')) {
                const query = [];
                // this.getStreamsInfo(streams['live']);
                for (let stream in streams['live']) {
                    if (!streams['live'].hasOwnProperty(stream)) continue;
                    query.push({ stream_key: stream });
                }
              
                this.setState({
                    live_streams: query
                }, () => {
                    console.log(this.state);
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        let streams = this.state.live_streams.map((stream, index) => {
            return (
                <div className="stream col-xs-12 col-sm-12 col-md-3 col-lg-4" key={index}>
                    <span className="live-label">LIVE {index+1}</span>
                    <Link to={'/rtmp/stream/' + stream.stream_key}>
                        <div className="stream-thumbnail">
                            <img width="200" src='https://i.ytimg.com/vi/qngojQDhQEA/maxresdefault.jpg' alt="live" />
                        </div>
                    </Link>

                    <span className="username">
                        {/* <Link to={'/stream/' + stream.stream_key}>
                            {stream.stream_key}
                        </Link> */}
                    </span>
                </div>
            );
        });

        return (
            <div className="container mt-5">
                <h4>Live Streams</h4>
                <div>
                    <p>Stream URL: <span style={{fontWeight: 800}}>rtmp://54.156.94.48:1935/live</span></p>
                    <p>Stream Key: <span style={{fontWeight: 800}}>default</span></p>
                </div>
                <hr className="my-4" />

                <div className="streams row">
                    {streams}
                </div>
            </div>
        )
    }
}