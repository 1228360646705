import React from 'react';
import './HomePage.css'
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <section>
            <div className="news">
                <Link to="/rtmp">
                    <figure className="article">
                        <img src="https://images-na.ssl-images-amazon.com/images/I/41suRhngrZL.jpg" alt="sdfhk" />
                        <h4 className="heading" style={{ position: 'absolute', zIndex: 2, top: 15, left: 15, color: '#fff' }}>RTMP LIVE</h4>
                        <figcaption>
                            <h3>RTMP LIVE</h3>
                            <p> Live Stream with RTMP server link </p>
                        </figcaption>
                    </figure>
                </Link>

                <Link to="/agora">
                    <figure className="article">
                        <img src="https://i.pinimg.com/736x/8c/f0/93/8cf093d06bb060ff91770fcec13d2e32.jpg" alt="sdfhk" />
                        <h4 className="heading" style={{ position: 'absolute', zIndex: 2, top: 15, left: 15, color: '#fff' }}>AGORA Camera LIVE</h4>
                        <figcaption>
                            <h3>AGORA Camera LIVE</h3>
                            <p> Live Stream with with your Camera </p>
                        </figcaption>
                    </figure>
                </Link>
            </div>

        </section>
    )
}


export default HomePage;
