import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'

class Home extends Component {

    render() {
        return (
            <div className="container">
                <div className="boxDiv">
                    {/* <div>
                        <Link to='/agora' >Home</Link>
                    </div> */}
                    <div>
                        <Link to='/agora/stream' >Watch</Link>
                    </div>
                    <div>
                        <Link to='/agora/host' >Host A new stream</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
