import React, { Component } from 'react';
import AgoraRTC from 'agora-rtc-sdk'
import config from '../config';
import axios from 'axios';
// import Plyr from 'plyr';
// import 'plyr/src/sass/plyr.scss';
import './VideoStream.css'

class VideoStream extends Component {
    role = 'audience'

    async componentDidMount() {

        const { data } = await axios.get(`${config.apiPath}/getToken`);

        // console.log(generate);

        let client = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
        });

        client.init(data.token);
        client.setClientRole(this.role);

        client.join(data.token, data.channelName, null, (uid) => {

        }, (err) => {
            console.log('err', err)
        })

        client.on("stream-added", (evt) => {
            
            client.subscribe(evt.stream, (err) => {
                console.log('err', err)
            });
        });

        client.on("stream-subscribed", (evt) => {
            let stream = evt.stream;
            this.removeVideoStream('liveDiv')
            setTimeout(() => {
                stream.play('liveDiv');
            },600)
        });

        client.on("stream-removed", (evt) => {
            let stream = evt.stream;
            stream.close();
            this.removeVideoStream('liveDiv');
        });
        // Remove the corresponding view when a remote user leaves the channel.
        client.on("peer-leave", (evt) => {
            let stream = evt.stream;
            stream.close();
            this.removeVideoStream('liveDiv');
        });
    }

    removeVideoStream(elementId) {
        let remoteDiv = document.getElementById(elementId);
        // if (remoteDiv) remoteDiv.removeChild(remoteDiv.childNodes[0]);
        remoteDiv.innerHTML = '<div></div>';
    }

    render() {
        return (
            <div id="remote-container" >
                <h2>Stream</h2>
                <div id="liveDiv"></div>
            </div>
        )
    }

}

export default VideoStream;