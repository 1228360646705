
import './App.css';
import { Route, HashRouter } from 'react-router-dom';
import Navbar from "./component-rtmps/Navbar";
import LiveStreams from "./component-rtmps/LiveStreams";
import VideoPlayer from "./component-rtmps/VideoPlayer";
import HomePage from './HomePage';

import Home from './component-agora/Home';
import StreamHost from './component-agora/SreamHost';
import VideoStream from './component-agora/VideoStream';

function App() {
  return (
    <HashRouter>
      <div>
        <Navbar />
        <Route exact={true} path="/" component={HomePage} />
        <Route exact={true} path="/rtmp" component={LiveStreams} />
        <Route exact={true} path="/rtmp/stream/:stream_key" component={VideoPlayer} />
        
        <Route exact={true} path="/agora" component={Home} />
        <Route exact={true} path='/agora/host' component={StreamHost} />
        <Route exact={true} path='/agora/stream' component={VideoStream} />

        {/* <Route exact path="/settings" render={props => (
          <Settings {...props} />
        )} /> */}
      </div>
    </HashRouter>
  );
}

export default App;
